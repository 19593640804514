import React from "react"
import Header from "../../components/services/servicesHeader"
import ServicesBottomNav from "../../components/services/servicesBottomNav"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import Background from "../../../assets/images/servicesHeader/design_icon.svg"
import awardImg from "../../../assets/images/servicesPages/awardWinning.png"
import arrow from "../../../assets/images/servicesPages/arrowDown.png"
import poster from "../../../assets/images/servicesPages/iddayPoster.png"


export default () => (
    <div id="design-page">
        <SEO title={'ICON Worldwide | Design Services '} 
            description="Design is the foundation of everything we do: meet our award-winning team of designers who can work across all media, from print to digital and video!"
        canonical={'https://icon-worldwide.com/services/design'}/>
        <Header headerTitle="Design" headerSubTitle="is the foundation of everything we do"  headerBackground={Background} />
        <div id="servicesPagesInner">
            <div className="winningTeam">
                <div className="text">
                    <h2>Our <span>award winning</span> team of designers are <span>passionate</span> about their work,
                    and are able to work across <span>all mediums</span> from <span>print</span> to <span>digital</span> to <span>video</span>
                    </h2>
                </div>
                <img src={awardImg} alt="Design services, ICON Worldwide, best brandig agency 2019 " title="ICON Worldwide, Design services, best brandig agency 2019"/>
            </div>
            <img className="arrowDown" src={arrow} alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <div className="poster" >
                <img src={poster} alt=" Design services, ICON Worldwide, iron deficiency day poster" title=" ICON Worldwide, Design services, iron deficiency day poster "/>
            </div>
            <div className="list">
                <div className="block left">
                    <h2>Corporate Identity</h2>
                    <p>Translating the “idea” into reality,
                    as manifested by a name and a visual strategy.
                    We leverage years of experience working with 
                    some of the world’s most powerful brands, and applying 
                    this experience to creating new brands which are built to last.</p>
                    <div className="words">
                        <h3>Name Development</h3><i>/</i><h3>Logo Development</h3><i>/</i><h3>Corporate Identity</h3>
                    </div>
                </div>

                <div className="block">
                    <h2>UI/UX Design</h2>
                    <p>Bringing your business or product to market in a simple,
                    powerful combination of visual and text able to be extended across 
                    all channels of communication.<br/><br/>
                    <a href="https://www.bestdesigns.co/best-website-designs" target="_blank">Best Website Design Companies of 2020 according to BestDesigns</a><br/><br/>
                    <a href="https://www.designrush.com/agency/ui-ux-design/trends/ux-patterns " target="_blank">UX Patterns</a></p>
                    <div className="words">
                        <h3>User Interface Design</h3><i>/</i><h3>User Experience Design</h3>
                    </div>
                </div>

                <div className="block right">
                    <h2>Campaign Development</h2>
                    <p>We create living, engaging user experiences across digital the full
                    range of product environments including websites and mobile applications.
                    Design that is intended to be engaged with individually towards specific
                    business objectives.</p>
                    <div className="words">
                        <h3>Messaging Strategy</h3><i>/</i><h3>Print and Digital Advertising </h3><i>/</i><h3>Outdoor Advertising</h3>
                    </div>
                </div>
                <div className="blackLine"></div>

                <div className="block left">
                    <h2>Print Communications</h2>
                    <p>The power of print is undiminished in today’s quick-read environment.
                         Brochures and print with a high-level text, design, and production 
                         deliver a powerful brand impact.</p>
                    <div className="words">
                        <h3>Brochure Design and Production</h3><i>/</i><h3>Collateral Systems </h3><i>/</i><h3> Direct Mail</h3>
                    </div>
                </div>

                <div className="block">
                    <h2>Information Design</h2>
                    <p>Expressing complex ideas via simple visuals designed to educate and inform.
                         Information design is a strategic and design discipline which transforms data
                          and processes into impactful visuals which transcend traditional and digital platforms.</p>
                    <div className="words">
                        <h3>Infographics</h3><i>/</i><h3>Illustrations </h3><i>/</i><h3>Iconography </h3>
                    </div>
                </div>

                <div className="block right">
                    <h2>Motion Graphics Design</h2>
                    <p>Engaging animations and videos custom built to tell your brand or product story. Video is increasingly utilized across websites and social media.</p>
                    <div className="words">
                        <h3>Animated Illustration</h3><i>/</i><h3>Video Production  </h3><i>/</i><h3>Teasers and Brand Films</h3>
                    </div>
                </div>
                <div className="yellowLine"></div>
            </div>
            <ServicesBottomNav/>
        </div>
        <Footer noScrollbar="true"/>
    </div>

)
